import React from "react";
import { ImageDataEdge } from "../../types";
import SliderImage from "./sliderImage";

const AutomaticCarousel = ({ images }: { images: ImageDataEdge[] }) => {
  return (
    <div className="slider">
      <div className="slide-track">
        {images.map((image) => (
          <div className="slide" key={image.node.id}>
            <SliderImage image={image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutomaticCarousel;
