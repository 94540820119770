import React from "react";
import Link from "../../lib/customLink";
import { ITripleSection } from "../../types";

export const TripleSection = ({
  sectionProps,
  sectionClassName,
  sections,
  tripleSectionSubtitle,
  tripleSectionTitle,
}: ITripleSection) => {
  return (
    <section className={`py-20 ${sectionClassName || ""}`} {...sectionProps}>
      <div className="container">
        {tripleSectionTitle && tripleSectionSubtitle && (
          <div className="mb-16 text-center">
            <h2 className="text-aerotec font-bold text-6xl mb-4">
              {tripleSectionTitle}
            </h2>
            <p className="font-semibold text-3xl">{tripleSectionSubtitle}</p>
          </div>
        )}
        <div className="flex flex-wrap gap-4 lg:flex-nowrap">
          {sections.map(
            (
              {
                description,
                title,
                Image,
                imageContainerClassName,
                imageContainerProps,
                linkText,
                linkUrl,
                externalLink,
              },
              index
            ) => (
              <div className="container" key={index}>
                <div
                  className={`mx-auto w-9/12 ${imageContainerClassName}`}
                  {...imageContainerProps}
                >
                  {Image}
                </div>
                <div className="text-center mb-6">
                  <div className="container">
                    <span className="text-aerotec text-3xl font-semibold mb-4 block">
                      {title}
                    </span>
                    <p className="text-gray-800 text-xl">{description}</p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  {externalLink ? (
                    <Link
                      to={linkUrl}
                      aria-label="Ver más información"
                      className="bg-aerotec rounded-md px-8 py-2 text-base text-white transition duration-300 ease-in-out hover:bg-aerotec-10"
                      target="_blank"
                    >
                      {/* @ts-ignore */}
                      {linkText || "Ver más información"}
                    </Link>
                  ) : (
                    <Link
                      to={linkUrl}
                      aria-label="Ver más información"
                      className="bg-aerotec rounded-md px-8 py-2 text-base text-white transition duration-300 ease-in-out hover:bg-aerotec-10"
                    >
                      {/* @ts-ignore */}
                      {linkText || "Ver más información"}
                    </Link>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};
