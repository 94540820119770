import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout/Layout";
import { Banner } from "../components/themed/homepage/banner";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { TripleSection } from "../components/themed/homepage/tripleSection";
import Parallax from "../components/themed/homepage/parallax";
import AutomaticCarousel from "../components/themed/automaticCarousel/automaticCarousel";
import GrupoAerotecLogos from "../components/themed/grupoAerotecLogos";
import { Video } from "../components/themed/video";
import { gsap } from "gsap";
import MainSection from "../components/themed/homepage/mainSection";

const IndexPage = ({
  data,
}: PageProps<{
  clientLogos: Queries.FileConnection;
  lanzamiento1: Queries.File;
  lanzamiento2: Queries.File;
}>) => {
  useEffect(() => {
    const options: IntersectionObserverInit = {
      threshold: 0.3,
    };

    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        const target: Element & { timeLine: gsap.core.Timeline } =
          entry.target as Element & {
            timeLine: gsap.core.Timeline;
          };

        if (entry.isIntersecting) {
          target.timeLine.play();
        } else {
          target.timeLine.reverse();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const animatedElements = document.querySelectorAll(".animate");

    animatedElements.forEach((element) => {
      const img = element.querySelector(".animated-image");
      const text = element.querySelector(".animated-text");

      const action = gsap
        .timeline({ paused: true, defaults: { duration: 1.5 } })
        .from(img, {
          translateX: "-40%",
          opacity: 0,
          ease: "power4.out",
        })
        .from(text, { translateX: "40%", opacity: 0, ease: "power4.out" }, "<");

      (element as Element & { timeLine: gsap.core.Timeline }).timeLine = action;
    });

    Array.prototype.forEach.call(animatedElements, (element) =>
      observer.observe(element)
    );

    return () => {
      Array.prototype.forEach.call(animatedElements, (element) =>
        observer.unobserve(element)
      );
    };
  }, []);

  return (
    <Layout
      navbar={{
        staticColor: "blue",
        staticOpacity: "solid",
      }}
    >
      {/* <section className="py-20">
        <div className="container bg-red-300">
          <div className="text-center">
            <h2 className="text-5xl font-bold uppercase">
              ¡Nuevos lanzamientos!
            </h2>
            <small>De Aerotec</small>
          </div>
          <div>
            <h3>Colgante</h3>
            <GatsbyImage
              image={data.lanzamiento1.childImageSharp?.gatsbyImageData!}
              alt="Colgante"
            />
          </div>
          <div>
            <h3>Demostand Curvo</h3>
            <GatsbyImage
              image={data.lanzamiento2.childImageSharp?.gatsbyImageData!}
              alt="Demostand curvo"
            />
          </div>
        </div>
      </section> */}
      <Banner />
      <MainSection
        title="Banderas"
        subtitle="De diferentes medidas"
        imageContainerClassName="animated-image"
        textContainerClassName="animated-text"
        sectionClassName="animate bg-white"
        description={
          <>
            Gran variedad de modelos de Banderas Publicitarias que reflejarán la
            imagen de tu marca, impresas en alta calidad fotográfica de hasta
            1440 dpis, ideales para espacios exteriores e interiores. Conoce el
            producto y nuestras banderas prediseñadas.
          </>
        }
        Image={
          <StaticImage
            className={"block mx-auto h-full -z-10"}
            src={`../images/banderas.webp`}
            alt="Banderas-Aerotec"
          />
        }
        buttonLink="/productos/banderas"
      />
      <MainSection
        title="Sky Dancers"
        subtitle="Aumenta las ventas en tu establecimiento"
        imageContainerClassName="animated-image"
        textContainerClassName="animated-text"
        sectionClassName="animate bg-white"
        description={
          <>
            Producto Publicitario llamativo para espacios exteriores e
            interiores para resaltar la imagen de tu marca y atraer a nuevos
            clientes. Diferentes modelos de forros y turbinas para proyectar la
            imagen que deseas en tu negocio. Checa las propiedades del producto.
          </>
        }
        Image={
          <StaticImage
            src="../images/sky.webp"
            alt="sky-dancers-aerotec"
            className="block mx-auto h-full -z-10"
          />
        }
        buttonLink="/productos/sky-dancers"
      />
      <MainSection
        title="Carpas"
        subtitle="Varios modelos y tipos de impresión"
        imageContainerClassName="animated-image"
        textContainerClassName="animated-text"
        sectionClassName="animate bg-white"
        description={
          <>
            Son la mejor opción en lugares al aire libre como: centros
            comerciales, plazas o puntos de venta son ideales para montarlas con
            facilidad y resaltar su marca.
          </>
        }
        Image={
          <StaticImage
            src="../images/carpa.webp"
            alt="carpa-aerotec"
            className="block mx-auto h-full -z-10"
          />
        }
        buttonLink="/productos/carpas"
      />
      <MainSection
        title="Módulos Stand"
        subtitle="De diferentes medidas"
        textContainerClassName="animated-text"
        imageContainerClassName="animated-image"
        sectionClassName="animate bg-white"
        description={
          <>
            Módulos publicitarios de gran resistencia al peso, con impresión
            Full Color en calidad fotográfica a 1440 dpis, Ideales para
            supermercados, exposiciones, etc. Conoce más de este producto.
          </>
        }
        Image={
          <StaticImage
            src="../images/stand.webp"
            alt="carpa-aerotec"
            className="block mx-auto h-full -z-10"
          />
        }
        buttonLink="/productos/demostands"
      />
      <TripleSection
        sectionClassName="bg-white"
        sections={[
          {
            title: "Publitubos",
            description: (
              <>
                Totem publicitario inflable con impresión completa para mostrar
                tu marca al público.
              </>
            ),
            linkUrl: "/productos/publitubos",
            Image: (
              <StaticImage
                src="../images/publitubo.webp"
                alt="Publitubo-aerotec"
              />
            ),
          },
          {
            title: "Botargas",
            description: (
              <>
                Botarga de alta calidad para mostrar tu marca en el local. De
                figura completamente personalizada para tu marca, inflables o de
                espuma de acuerdo a tu presupuesto.
              </>
            ),
            linkUrl: "/productos/botargas",
            Image: (
              <StaticImage
                src="../images/botargas.webp"
                alt="Botargas-aerotec"
              />
            ),
          },
          {
            title: "Inflables",
            description: (
              <>
                Réplicas, arcos y más productos inflables con impresión
                personalizada de logotipo de tu marca. Ideal para eventos
                masivos al aire libre.
              </>
            ),
            linkUrl: "/productos/inflables",
            Image: (
              <StaticImage
                src="../images/inflables.webp"
                alt="Inflables-aerotec"
              />
            ),
          },
        ]}
      />
      <TripleSection
        sectionClassName="bg-white"
        tripleSectionTitle="Línea de Productos Sanitizantes (anti Covid-19)"
        tripleSectionSubtitle="Estos productos son alternativas para el regreso a actividades de pequeños y grandes negocios."
        sections={[
          {
            title: "Módulo de Sanitización todo en uno",
            description: (
              <>
                Incluye toma de temperatura digital, sistema de sanitización y
                dispensador de gel antibacterial.
              </>
            ),
            linkUrl: "https://https://accesosanitizante.com",
            Image: (
              <StaticImage
                src="../images/modulo.webp"
                alt="Módulo-sanitizante-aerotec"
              />
            ),
            externalLink: true,
          },
          {
            title: "Cubrebocas",
            description: <>Con el logotipo de tu marca, personalizado.</>,
            linkUrl: "https://https://accesosanitizante.com",
            Image: (
              <StaticImage
                src="../images/cubre.webp"
                alt="Cubrebocas-aerotec"
              />
            ),
            externalLink: true,
          },
          {
            title: "Líquido Sanitizante",
            description: <>Para sanitizar áreas de trabajo.</>,
            linkUrl: "https://https://accesosanitizante.com",
            Image: (
              <StaticImage
                src="../images/liquido.webp"
                alt="Líquido-sanitizante-aerotec"
              />
            ),
            externalLink: true,
          },
        ]}
      />
      <Parallax />
      <div className="py-20 bg-white">
        <p className="container text-center font-semibold text-3xl md:text-5xl mb-10 text-black">
          Clientes que han confiado en nosotros
        </p>
        <AutomaticCarousel images={(data as any).clientLogos.edges} />
      </div>
      <div className="bg-white py-20 w-full">
        <div className="container flex flex-wrap md:items-center md:flex-nowrap">
          <Video
            Tag="div"
            videoProps={{
              title: "Video de Aerotec",
              src: "https://www.youtube.com/embed/GYxLDl5g3S8",
              frameBorder: "0",
              className: "w-full h-full",
            }}
            className="w-full h-screen-1/4 mb-6 sm:h-screen-2/4 md:w-7/12 md:h-screen-1/3 lg:h-screen-2/4"
          />
          <div className="text-center leading-7 text-gray-900 w-11/12 mx-auto sm:text-lg md:w-4/12 lg:text-xl lg:leading-9 md:font-semibold">
            <p>
              Somos una empresa innovadora y con gran capacidad, especializada
              en la generación de soluciones publicitarias, con más de 15
              experiencia, diseñando, fabricando e implementando calidad en
              todos los productos para captar la atención de los clientes.
              Nuestro posicionamiento lo ganamos y lo corroboran las grandes
              empresas que han confiado en nosotros.
            </p>
          </div>
        </div>
      </div>
      <GrupoAerotecLogos />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    clientLogos: allFile(
      filter: {
        relativeDirectory: { eq: "clientes" }
        extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 200)
          }
          id
          base
        }
      }
    }
    # lanzamiento1: file(
    #   relativeDirectory: { eq: "productos/colgante" }
    #   name: { eq: "colgante" }
    # ) {
    #   childImageSharp {
    #     gatsbyImageData(quality: 100, placeholder: BLURRED)
    #     id
    #   }
    # }
    # lanzamiento2: file(
    #   relativeDirectory: { eq: "productos/demostands" }
    #   name: { eq: "stand-curvo" }
    # ) {
    #   childImageSharp {
    #     gatsbyImageData(quality: 100, placeholder: BLURRED)
    #     id
    #   }
    # }
  }
`;

export default IndexPage;
