import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { ImageDataEdge } from "../../types";

const SliderImage = ({ image }: { image: ImageDataEdge }) => {
  const img = getImage(image.node.childImageSharp.gatsbyImageData);

  return (
    <>
      <GatsbyImage image={img!} alt={image.node.base} />
    </>
  );
};

export default SliderImage;
