import { graphql, useStaticQuery } from "gatsby";
import React, { useLayoutEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

/*
  const pathRef = React.useRef<SVGPathElement>(null);
  const skyDancerPathRef = React.useRef<SVGPathElement>(null);

  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
      yoyo: true,
    });
    tl.to(pathRef.current, {
      morphSVG: "#pos-2",
    })
      .to(pathRef.current, {
        morphSVG: "#pos-3",
      })
      .to(pathRef.current, {
        morphSVG: "#pos-4",
      });
    const s_tl = gsap.timeline({ repeat: -1, yoyo: true });

    s_tl
      .to(skyDancerPathRef.current, {
        morphSVG: "#sky-position-2",
      })
      .to(skyDancerPathRef.current, {
        morphSVG: "#sky-position-3",
      });
  }, []);

 <div className="bg-red-500 w-full h-screen top-0 left-0 pt-20 flex justify-center items-center">
      <div className="h-5/6">
        <DobleSkyDancer ref={pathRef} />
      </div>
      <div className="h-5/6">
        <SkyDancer ref={skyDancerPathRef} className="w-auto h-full" />
      </div>
      <p className="text-xl md:text-4xl lg:text-6xl font-bold text-center block">
        Banner en Construcción
      </p>
    </div>
*/
export const Banner = () => {
  useLayoutEffect(() => {}, []);
  const data = useStaticQuery<{
    image: Queries.FileConnection;
  }>(graphql`
    query {
      image: allFile(filter: { base: { eq: "banner-t.jpg" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  return (
    <div className="bg-white w-full h-auto top-0 left-0 pt-20 flex justify-center items-center">
      <GatsbyImage
        image={data.image.edges[0].node.childImageSharp?.gatsbyImageData!}
        alt="Banner Aerotec Publicidad"
        className="w-full h-full"
      />
    </div>
  );
};
