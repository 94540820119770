import React from "react";  
import Link from "../../lib/customLink";
import { IMainSection } from "./types";

export const MainSection = (props: IMainSection) => {
  return (
    <section
      className={`min-h-screen py-20 md:flex items-center ${
        props.sectionClassName || ""
      }`}
      {...props.sectionProps}
    >
      <div className="container h-full md:flex md:items-center">
        <div
          className={`flex justify-center w-full h-auto mb-6 md:w-6/12 ${
            props.imageContainerClassName || ""
          }`}
          {...props.imageContainerProps}
        >
          {props.Image}
        </div>
        <div
          {...props.textContainerProps}
          className={`md:w-6/12 ${props.textContainerClassName}`}
        >
          <div
            className={`mb-6 ${props.textHeaderClassName}`}
            {...props.titleProps}
          >
            <h2
              className="text-aerotec font-bold uppercase text-6xl text-center mb-3 lg:text-7xl lg:text-left"
              {...props.titleProps}
            >
              {props.title}
            </h2>
            <p
              className="font-semibold text-center text-3xl lg:text-4xl lg:text-left"
              {...props.subtitleProps}
            >
              {props.subtitle}
            </p>
          </div>
          <p
            className="leading-7 text-center text-xl mb-6 text-gray-800 lg:text-2xl lg:leading-9 lg:text-left"
            {...props.descriptionProps}
          >
            {props.description}
          </p>
          <div className="flex justify-center lg:justify-start">
            <Link
              to={props.buttonLink}
              aria-label="Ver más información"
              className="bg-aerotec rounded-md px-10 py-3 text-lg text-white transition duration-300 ease-in-out hover:bg-aerotec-10"
              {...props.buttonProps}
            >
              {/* @ts-ignore */}
              {props.buttonText || "Ver más información"}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
