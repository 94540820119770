import gsap from "gsap";
import React from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const Parallax = () => {
  return (
    <div className="parallax-container">
      <StaticImage
        src="../../../images/parallax-index.webp"
        alt="Banderas-parallax"
        className="bg-parallax-image"
      />
    </div>
  );
};

export default Parallax;
